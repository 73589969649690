.connect-with-me {
    text-align: center;
    margin-top: 0px;
    padding-left: 10%;
    padding-right: 10%;
    height: auto;
    padding-bottom: 10%;
  }
  
  .social-links {
    display: flex;
    justify-content: space-between;
    /* padding: 50px 20px;  */
    padding: 3%;
    max-width: 100%;
    margin: 0 auto;
  }
  
  .social-icon {
    width: 100%; 
    height: auto;
    max-width: 100px;
  }
  
  
  .social-links a {
    display: inline-block;
    margin: 0 10px;
    text-decoration: none;
    position: relative;
    transition: transform 0.5s;
  }
  
  .social-links a:hover {
    transform: scale(1.2);
  }
  
  .social-links img:hover {
    filter: brightness(1.2);
  }
 
  