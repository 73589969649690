/* Define mobile-friendly styles */

.projects {
  margin: 0 auto;
  padding: 20px;
  font-family: 'Helvetica Neue', sans-serif;
  /* color: rgb(255, 255, 255);
  background-color: #333; */
  color: #333;
  background-color: #ffffff;
  text-align: center;
}

.section-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.journey-list {
  display: flex;
  flex-direction: column; /* Display projects in a column for mobile */
  align-items: center; /* Center projects horizontally */
}

.journey-item {
  margin-bottom: 40px;
  text-align: center;
}

.journey-image {
  width: 300px; 
  height: 200px; /* Maintain aspect ratio */
  border-radius: 8px;
  margin-bottom: 10px;
}

.journey-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.journey-description {
  font-size: 14px;
  line-height: 1.5;
}

.journey-link {
  color: #00aaff; /* Add a color for links */
  text-decoration: none;
  font-weight: bold;
  margin-top: 10px;
  display: block; /* Make the link a block element for spacing */
}

.carousel-buttons {
  margin-top: 20px;
  text-align: center;
}

.carousel-button {
  font-size: 16px;
  font-weight: bold;
  padding: 8px 20px; /* Increased horizontal padding for more space */
  margin: 0 10px; /* Increased horizontal margin for more space */
}