* {
  margin: 0;
  padding: 0;
}

h1{
  background-size: cover;
  text-align: center;
}

body {
  font-family: Arial, sans-serif;
}

header {
  background-color: rgba(51, 51, 51, 0);
  color: #fff;
  padding: 10px;
  
}

nav ul {
  list-style-type: none;
  position: fixed;
}

nav ul li {
  display: inline-block;
  margin-right: 10px;
}

nav ul li a {
  color: rgb(0, 255, 30);
  text-decoration: none;
}

section {
  padding: 50px 0;
  text-align: center;
}



.title-content {
  margin-top: 100px;
}

h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

h2 {
  font-size: 24px;
  margin-bottom: 30px;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
}

.btn:hover{
  filter: brightness(1.2);

}

.box {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
  color: white;
  width: 70%;
  max-width: 800px;
}

.project {
  margin-bottom: 30px;
}

.project img {
  width: 100%;
  max-width: 300px;
  margin-bottom: 10px;
}

footer {
  background-color: #333;
  color: #fff;
  padding: 10px;
  text-align: center;
}