#about {
    background: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
  }
  
  
  h2 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 18px;
    line-height: 1.6;
  }

  