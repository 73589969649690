/* Experience.css */

.section-title {
  text-align: center;
  font-size: 28px;
  margin-bottom: 30px;
}

.experience-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
  justify-content: space-between;
  padding: 40px;
}

.experience-title{
  color: #333
}

.experience {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.experience-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 20px;
}

.experience-details {
  text-align: center;
  max-width: 50%;
}

h3 {
  font-size: 22px;
  margin-bottom: 10px;
}

.date {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
}

.experience-description {
  font-size: 16px;
  line-height: 1.5;
}
