#title {
    background-image: url(images/coolView.jpeg);
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    height: 750px;
    background-position: center;
    color: #fff;
}


@media (max-width: 1000px) {
    #title {
        background-size: 1200px; 
        background-position: top;
        background-attachment: scroll;
        background-repeat: no-repeat;
    }
}

.name{
    /* font-family: Snell Roundhand, cursive; */
    font-size: 500%;
    color: #333;
    font-weight: bold;
    margin-bottom: 0px;
}
.name2{
    /* font-family: Snell Roundhand, cursive; */
    color: #333;
    font-weight: bold;
    margin-top: 0;
}
/* #email-button{
    margin-top: 00px;
} */